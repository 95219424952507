<template>
  <div>
    <el-card v-loading="loading" style="min-height: 45rem">
      <div class="flex-def flex-cCenter flex-zBetween">
        <div>
          <el-input-number v-model="generateForm.num"></el-input-number>
          <el-button @click="generate" style="margin-left: 10px">生成空码</el-button>
        </div>
        <div>
          <el-button @click="exportZip">导出全部（{{total}}）空码</el-button>
          <el-popconfirm
              title="确定移除吗？"
              @confirm="clear"
              style="margin-left: 1rem"
          >
            <el-button slot="reference" type="danger">移除全部空码</el-button>
          </el-popconfirm>
        </div>
      </div>
      <div class="flex-def flex-warp">
        <el-row style="width: 100%">
          <el-col v-for="(item,key) in list" :key="key" :span="3">
            <div  class="mini-code-box flex-def flex-zTopBottom flex-zCenter flex-cCenter">
              <img :src="item.mini_code | tomedia" class="mini-code" alt="">
              <div class="y-desc">ID: {{item.id}}</div>
              <div class="y-desc">{{item.created_at | date}}</div>
            </div>
          </el-col>
        </el-row>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="32" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "hollow",
  data() {
    return {
      generateForm:{
        num:0,
      },
      page:1,
      total:0,
      list:[],

      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    clear(){
      this.loading = true;
      this.$u.api.card.plugin.wifi.hollowClear().then((res)=>{
        this.$message.success(`操作成功:成功移除${res}个空码`);
        this.load();
      }).finally(()=>{
        this.loading = false;
      })
    },
    load(){
      this.$u.api.card.plugin.wifi.codeSearch({
        page:this.page,
        need_hollow:true,
        size:32,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    generate(){
      this.loading = true;
      this.$u.api.card.plugin.wifi.hollowGenerate({num:this.generateForm.num}).then(()=>{
        this.generateForm.num = 0;
        this.loading = false;
        this.$message.success("操作成功");
        this.load();
      })
    },
    exportZip(){
      this.$u.api.card.plugin.wifi.hollowExport().then(res=>{
        window.open(res,"_blank")
      })
    },
  },
}
</script>

<style scoped>
.mini-code{
  width: 100px;
  height: 100px;
  display: flex;
}
.mini-code-box{
  margin: 15px auto;
}
</style>